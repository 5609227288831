import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import { AnimatePresence, motion } from "framer-motion"
import Img from "gatsby-image"

import { Link as ScrollLink } from "react-scroll"

// Hooks & Querys
import { QueryFragments } from "../Graphql/queryFragments" // eslint-disable-line
import { useMediaQuery } from "@react-hook/media-query"

import { useInView } from "react-intersection-observer"

import { PageMeta } from "../components/pageMeta"

import { PortableTextBlock } from "../components/sanity"
import { ContainImage } from "../components/media/containImage"

// Animation Settings
import { leftVariants } from "../config/animation"

const WineItem = ({ _rawText, setActiveIndex, bottleShot, bgColor, index }) => {
  const [ref, inView] = useInView({
    /* Optional options */
    threshold: 0.5,
  })

  const matches = useMediaQuery("only screen and (max-width: 768px)")

  useEffect(() => {
    inView && setActiveIndex(index)
  }, [inView, setActiveIndex, index])

  return (
    <div
      className="prose md:prose-lg md:min-h-screen mb-20 md:my-28"
      ref={ref}
      id={`wine-${index}`}
    >
      {matches && (
        <div
          className="w-full relative mb-14 py-11"
          style={{ backgroundColor: bgColor && bgColor.hex }}
        >
          {bottleShot && bottleShot.asset && (
            <Img fluid={bottleShot.asset.fluid} className="w-full" />
          )}
        </div>
      )}
      <div className="px-10 md:px-0">
        <PortableTextBlock text={_rawText} />
      </div>
    </div>
  )
}

const BottleShot = ({ bottleShot, bgColor, key }) => {
  return (
    <motion.div
      className="h-full w-full flex justify-center items-center absolute top-0 left-0 "
      style={{ backgroundColor: bgColor && bgColor.hex }}
      key={key}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {bottleShot && bottleShot.asset && (
        <ContainImage
          fluid={bottleShot.asset.fluid}
          className="w-full h-5/6 object-contain top-0 left-0"
        />
      )}
    </motion.div>
  )
}

const WinesPage = ({ data: { page } }) => {
  const { meta, items } = page || {}
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <>
      <PageMeta {...meta} />
      <div className="grid md:grid-cols-2">
        <motion.div
          className="hidden md:block col-start-1 h-screen bg-black sticky top-0"
          variants={leftVariants}
        >
          <nav className="absolute text-white top-0 left-0 z-40 h-screen justify-center pl-8 hidden md:flex md:flex-col">
            {items.map((item, index) => (
              <ScrollLink
                to={`wine-${index}`}
                containerId="wines-container"
                className="bg-white rounded-full h-2 w-2 my-2 cursor-pointer"
                smooth={true}
                duration={500}
                offset={-200}
                style={{ opacity: activeIndex === index ? "1" : "0.25" }}
              />
            ))}
          </nav>
          <AnimatePresence exitBeforeEnter>
            <BottleShot {...items[activeIndex]} key={activeIndex} />
          </AnimatePresence>
        </motion.div>
        <motion.div
          className="bg-white px-0 py-0 overflow-auto md:col-start-2 md:h-screen md:px-20"
          variants={leftVariants}
          id="wines-container"
        >
          <div className="md:pt-32">
            {items.map((item, index) => (
              <WineItem
                {...item}
                setActiveIndex={setActiveIndex}
                index={index}
              />
            ))}
          </div>
        </motion.div>
      </div>
    </>
  )
}

export default WinesPage

export const pageQuery = graphql`
  query {
    page: sanityWinesPage(id: { eq: "-1f3a055b-2e5e-57b0-b35a-cb6f6149c2b6" }) {
      items {
        title
        bgColor {
          hex
        }
        bottleShot {
          asset {
            fluid(maxWidth: 1440) {
              ...GatsbySanityImageFluid
            }
          }
        }
        _rawText(resolveReferences: { maxDepth: 10 })
      }
    }
  }
`
